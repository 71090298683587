import { graphql, StaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import ImageInContext from "../Media/ImageInContext";

const TeamPictureWall = () => {
  return (
    <StaticQuery
      query={graphql`
        query TeamPictureWall {
          teamOne: file(base: { eq: "team-brl.jpg" }) {
            childImageSharp {
              fluid(cropFocus: CENTER, maxHeight: 540, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          teamTwo: file(base: { eq: "team-robin-hood.jpg" }) {
            childImageSharp {
              fluid(maxHeight: 540, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          teamThree: file(base: { eq: "team-court.jpg" }) {
            childImageSharp {
              fluid(maxHeight: 540, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => (
        <StyledTeamPictureWall>
          <ImageInContext alt="Upsolve team at Blue Ridge Labs" fluid={data.teamOne.childImageSharp.fluid} />
          <ImageInContext
            alt="Upsolve team at the Albama Bankruptcy Courthouse"
            fluid={data.teamThree.childImageSharp.fluid}
          />
          <ImageInContext
            alt="Upsolve team at the Robin Hood Heroes Breakfast"
            fluid={data.teamTwo.childImageSharp.fluid}
          />
        </StyledTeamPictureWall>
      )}
    />
  );
};

const StyledTeamPictureWall = styled.div`
  width: 100%;
  max-width: 95%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 3em;
  padding: 0 0 2em;
  transform: scale(0.8);
  & > div {
    height: auto;
    max-height: auto;
    width: 100%;
    border-radius: 30px 128px;
    overflow: hidden;
    &:nth-of-type(2) {
      height: 120%;
      width: 140%;
      margin: -20%;
      top: 20%;
      z-index: 10;
      border: 12px solid white;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
    padding: 0 4em;
    & > div {
      margin: 0 auto;
      max-with: 80%;
    }
  }
`;

export default TeamPictureWall;
