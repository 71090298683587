import styled, { css } from "styled-components";
import { ACSS, theme } from "@upsolve/ui";

import { ButtonLink } from "../Inputs/Button.span";

const Hero = styled.div`
  position: relative;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  padding: ${(props) => (props.padding ? props.padding : "3em 1em")};
  display: flex;
  flex-direction: row-reverse;
  a:not(${ButtonLink}) {
    ${ACSS}
  }
  h5,
  button {
    margin-bottom: 1em;
  }
  svg. {
    fill: red;
  }
  div {
    display: flex;
  }
  div:first-of-type {
    flex: 2.5;
    justify-content: center;
    align-items: center;
    margin: 0 1em;
    img {
      width: 100%;
    }
    @media screen and (max-width: 45em) {
      margin: 1em;
    }
  }
  div:last-of-type {
    flex: 2;
    flex-direction: column;
    justify-content: center;
    margin: 0 1em;
    a,
    small {
      margin-top: 0.5em;
    }
    @media screen and (max-width: 45em) {
      text-align: center;
    }
  }
  @media screen and (max-width: 45em) {
    padding: 3em 1em;
    flex-direction: column-reverse;
  }
`;

export const HeroText = styled(Hero)`
  flex-direction: column;
  padding: ${(props) => (props.padding ? props.padding : "3em 1em")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "")};
  ${(props) => {
    if (props.align)
      return css`
        display: flex;
        align-items: center;
      `;
  }}
  div, div:first-of-type {
    display: block;
    padding: 1em 0;
    margin: 0;
  }
  ol {
    padding-left: 1em;
  }
  li {
    font-size: 1em;
    margin-bottom: 0.5em;
    font-weight: 400;
  }
  b {
    color: ${theme.colors.brand["500"]};
  }
  @media screen and (max-width: 45em) {
    padding: 4em 1.5em;
    flex-direction: column;
    text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
  }
`;

export default Hero;
