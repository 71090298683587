import { graphql, StaticQuery } from "gatsby";
import Link from "gatsby-link";
import styled from "styled-components";
import React, { Component } from "react";
import { H1, H2, H4, P, Small, theme } from "@upsolve/ui";
import { EVENTS } from "@upsolve/shared";

import { track } from "../components/analytics/track";
import * as CONTENT_GROUPS from "../components/analytics/contentGroups";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import SEO from "../components/SEO/SEO";
import TopLevelLayout from "../components/Layout/TopLevelLayout";
import { HeroText } from "../components/Layout/Hero.div";
import TeamPictureWall from "../components/team/TeamPictureWall";
import CSSInjector from "../components/Layout/CSSInjector";
import WaveSVG from "../../static/images/illustrations/wave.svg";
import { CTATarget } from "../components/CTA/getCTAData";

const contentGroup = CONTENT_GROUPS.LANDING_PAGE;
const templateProps = {
  pageComponentName: "Careers",
  pageComponentVersion: 0,
};
const trackingPageContext = { contentGroup, ...templateProps };

class Careers extends Component {

  componentDidMount() {
    track(EVENTS.PAGE_VIEW, { pageContext: trackingPageContext });
  }

  render() {
    return (
      <TopLevelLayout showPersistentCta={false}>
        <div className="index-container">
          <SEO
            title="Careers | Upsolve "
            description="We're on a mission to give millions of Americans a fresh start to their lives by making the financial safety net accessible. Join in."
            image="https://upsolve.org/images/meta-probono.png"
            url="https://upsolve.org/careers/"
          />
          <CSSInjector
            injectCSS={`body { background: linear-gradient(0deg, ${theme.colors.brand[100]}, ${theme.colors.brand[300]}); }`}
          />
          <Header allowMobileFooter={false} trackingPageContext={trackingPageContext} ctaTarget={CTATarget.DEBT_TRIAGE} />

          <StyledCareers>
            <div className="careers__header">
              <Small>Join the Movement</Small>
              <H2 as="h1">Help millions access their rights and justice</H2>
              <H4 as="h2">
                Upsolve is an award-winning nonprofit technology startup that helps the victims of our broken financial
                system get back on their feet. Bankruptcy is a lifeline for Americans because it wipes their slate
                clean, improves their credit, and increases their chances of getting a job. We're starting with
                bankruptcy, but we aim to change the financial and legal systems in America.
              </H4>
            </div>

            <div className="careers__transition-waves">
              <WaveSVG />
              <WaveSVG />
            </div>

            <div className="careers__list">
              <div>
                <H2>Current Openings</H2>
                <StaticQuery
                  query={graphql`
                    query JobsListQuery {
                      allContentfulPage(
                        sort: { fields: [title], order: DESC }
                        filter: { type: { eq: "jobListing" } }
                      ) {
                        nodes {
                          path
                          title
                        }
                      }
                    }
                  `}
                  render={(data) => (
                    <ul>
                      {data.allContentfulPage.nodes.map((jobListing) => (
                        <li>
                          <Link to={jobListing.path}>{jobListing.title}</Link>
                          <br />
                        </li>
                      ))}
                    </ul>
                  )}
                />
              </div>
              <br />
              <P>
                Last year, 19 million American households would have benefitted from filing for bankruptcy but fewer
                than half a million did file. This is because it’s impossible for low-income people to file for
                bankruptcy, due to complicated forms and lawyers cost $2,000 on average. At Upsolve, you’ll help more
                clients in one month than many nonprofits help in multiple years. We’re supported by the top funders in
                our space. This includes the Chan Zuckerberg Initiative, ex-Google CEO Eric Schmidt, the Robin Hood
                Foundation, and the U.S. Government. Meet the <Link to="/team">team</Link>!
              </P>
              <P>
                Here are recent articles about Upsolve from the{" "}
                <a
                  href="https://www.wsj.com/articles/for-struggling-consumers-a-cheaper-way-to-file-for-bankruptcy-1505096071"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  Wall Street Journal
                </a>
                ,{" "}
                <a
                  href="https://www.washingtonpost.com/outlook/what-happens-when-you-cant-afford-to-go-bankrupt/2018/03/02/343fd882-1d8e-11e8-9de1-147dd2df3829_story.html?utm_term=.192f4d0a2a1b"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  Washington Post
                </a>
                , and{" "}
                <a
                  href="https://www.bna.com/turbotax-bankruptcy-upsolve-n57982092831/"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  Bloomberg
                </a>
                .
              </P>
              <TeamPictureWall />
            </div>
          </StyledCareers>
          <Footer />
        </div>
      </TopLevelLayout>
    );
  }
}

const StyledCareers = styled.main`
  div.careers__header {
    height: 440px;
    padding: 5em 1em 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    h1,
    h2,
    h4,
    p,
    small {
      color: white;
      max-width: 960px;
      line-height: 150%;
    }
    h1 {
      margin-bottom: 0.25em;
    }
    small {
      opacity: 0.5;
    }
    h2,
    h4 {
      opacity: 0.8;
    }
    @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
      min-height: 80vh;
      height: calc(100vh + 100px);
      justify-content: flex-start;
      h1 {
        font-size: 40px;
        line-height: 140%;
      }
      h2 {
        font-size: 18px;
      }
    }
  }
  div.careers__transition-waves {
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    height: auto;
    width: 100vw;
    max-width: 100vw;
    margin: -9em 0 -3em;
    overflow: hidden;
    svg {
      height: auto;
      width: 150vw;
    }
    svg:first-of-type {
      transform: rotate(-2deg) translateX(-5%) translateY(62%);
      path {
        fill: ${(props) => props.theme.colors.brand[500]};
      }
    }
    svg:last-of-type {
      transform: rotate(2deg) translateX(-7%);
      path {
        fill: white;
      }
    }
  }
  .careers__list {
    position: relative;
    z-index: 100;
    width: 100%;
    background: white;
    padding: 0 0 2em;
    & > div {
      max-width: 960px;
      margin: 0 auto;
      text-align: center;
    }
    & > h2,
    & > p {
      max-width: 800px;
      text-align: center;
    }
    & > h2 {
      margin: -2em auto 0.25em;
      color: ${(props) => props.theme.colors.brand[500]};
    }
    & > p {
      padding: 0 1em;
      margin: 0 auto 3em;
    }
    a,
    a:hover,
    a:visited,
    a:active {
      color: ${(props) => props.theme.colors.brand[500]};
    }
    li {
      font-size: 20px;
      margin-bottom: 0.5em;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
    div.careers__transition-waves {
      margin: -9em 0 -2em;
    }
    .careers__list {
      padding: 2em 0;
    }
  }
`;

export default Careers;
