import React from "react";
import styled, { css } from "styled-components";
import { theme } from "@upsolve/ui";

export const ButtonCSS = css`
  box-sizing: border-box;
  background: ${theme.colors.brand["500"]};
  border: 1px solid ${theme.colors.brand["500"]};
  border-bottom: 3px solid ${theme.colors.brand["300"]};
  color: ${theme.colors.white["900"]};
  border-radius: 4px;
  text-decoration: none;
  justify-content: center;
  text-align: center;
  transition: 0.1s color;
  cursor: pointer;
  outline: none;
  max-width: 100%;
  ${(props) => (props.width ? `min-width: ${props.width};` : "")}
  ${(props) => {
    if (props.size === "xs") {
      return `
        padding: .3em .6em;
        font-size: .8em;
        font-weight: 500;
      `;
    }
    if (props.size === "sm") {
      return `
        padding: .5em 1em;
        font-size: .8em;
        font-weight: 500;
      `;
    }
    if (props.size === "md" || props.size == null) {
      return `
        padding: .6em 1.2em;
        font-size: 1em;
        font-weight: 500;
      `;
    }
    if (props.size === "lg") {
      return `
        padding: .8em 1.4em;
        margin: .6em 1.2em;
        font-size: 1.4em;
        font-weight: 500;
        border-bottom-width: 5px;
      `;
    }
    if (props.size === "xl") {
      return `
        width: 100%;
        padding: 1em 2em;
        font-size: 1.6em;
        text-align: center;
        font-weight: 500;
        border-bottom-width: 7px;
      `;
    }
    return `
      padding: .6em 1.2em;
      font-size: 1em;
      font-weight: 500;
    `;
  }}
  ${(props) => {
    if (props.inverted) {
      return css`
        background: transparent;
        border: 1px solid ${theme.colors.brand["500"]};
        color: ${theme.colors.brand["500"]};
      `;
    }
    return css`
      &:hover {
        background: ${theme.colors.brand["300"]};
      }
    `;
  }}
  ${(props) => {
    if (props.disabled) {
      return css`
        opacity: 0.5;
        cursor: not-allowed;
      `;
    }
  }}
  ${(props) => {
    if (props.buttonType === "white" && props.inverted) {
      return css`
        background: transparent;
        border: 1px solid ${theme.colors.white["900"]};
        color: ${theme.colors.white["900"]};
      `;
    }
    if (props.buttonType === "white" && !props.inverted) {
      return css`
        background: ${theme.colors.white["900"]};
        border: 1px solid ${theme.colors.white["300"]};
        border-bottom: 3px solid ${theme.colors.white["300"]};
        color: ${theme.colors.brand["500"]};
        &:hover {
          background: ${theme.colors.white["300"]};
          border: 1px solid ${theme.colors.white["300"]};
        }
      `;
    }
    if (props.buttonType === "warning" && props.inverted) {
      return css`
        background: transparent;
        border: 1px solid ${theme.colors.red["500"]};
        color: ${theme.colors.red["500"]};
      `;
    }
    if (props.buttonType === "warning" && !props.inverted) {
      return css`
        background: ${theme.colors.red["500"]};
        border: 1px solid ${theme.colors.red["500"]};
        border-bottom: 3px solid ${theme.colors.red["300"]};
        color: white;
        &:hover {
          background: ${theme.colors.red["300"]};
          border: 1px solid ${theme.colors.red["300"]};
        }
      `;
    }
    if (props.buttonType === "success" && props.inverted) {
      return css`
        background: transparent;
        border: 1px solid ${theme.colors.green["300"]};
        color: ${theme.colors.green["300"]};
      `;
    }
    if (props.buttonType === "success" && !props.inverted) {
      return css`
        background: ${theme.colors.green["300"]};
        border: 1px solid ${theme.colors.green["300"]};
        border-bottom: 3px solid ${theme.colors.green["100"]};
        color: white;
        &:hover {
          background: ${theme.colors.green["300"]};
          border: 1px solid ${theme.colors.green["300"]};
        }
      `;
    }
    if (props.buttonType === "default" && props.inverted) {
      return css`
        background: transparent;
        border: 1px solid ${theme.colors.gray["900"]};
        color: ${theme.colors.gray["500"]};
      `;
    }
    if (props.buttonType === "default" && !props.inverted) {
      return css`
        background: ${theme.colors.gray["900"]};
        border: 1px solid ${theme.colors.gray["900"]};
        border-bottom: 3px solid ${theme.colors.gray["700"]};
        color: white;
        &:hover {
          background: ${theme.colors.gray["700"]};
          border: 1px solid ${theme.colors.gray["700"]};
        }
      `;
    }
  }}
`;

export const Button = styled.button`
  ${ButtonCSS}
  font-weight: 700;
`;

export const ButtonLink = styled.a`
  ${ButtonCSS}
`;

export class ButtonTypeform extends React.Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `(function() { var qs,js,q,s,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm_share", b="https://embed.typeform.com/"; if(!gi.call(d,id)){ js=ce.call(d,"script"); js.id=id; js.src=b+"embed.js"; q=gt.call(d,"script")[0]; q.parentNode.insertBefore(js,q) } })()`;
    document.body.appendChild(script);
  }

  render() {
    return (
      <ButtonLink className="typeform-share" data-mode="popup" {...this.props}>
        {this.props.children || "Get Started"}
      </ButtonLink>
    );
  }
}

export default Button;
